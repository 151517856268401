import React from 'react';

const Footer = () => {

    return (

       <footer className="bg-black text-white py-8">
       <div className="max-w-7xl mx-auto px-4 flex flex-col items-center md:flex-row justify-between">
         <p className="mb-4 md:mb-0">&copy; 2024 Miss Nzhelele. All rights reserved.</p>
         <div className="flex space-x-4">
           <a href="#" className="hover:text-yellow-500">Privacy Policy</a>
           <span className="hidden md:inline">|</span>
           <a href="#" className="hover:text-yellow-500">Terms of Service</a>
           <span className="hidden md:inline">|</span>
           <a href="#" className="hover:text-yellow-500">Contact</a>
         </div>
       </div>
     </footer>

    );

};

export default Footer;