import React, { useState } from 'react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(prev => !prev);

  return (
    <header className="bg-black text-white fixed top-0 w-full z-20 shadow-md">
      <div className="max-w-7xl mx-auto flex justify-between items-center p-4">
        <div className="text-lg font-bold text-yellow-500">Miss Nzhelele</div>
        <nav className="hidden md:flex space-x-6">
          <a href="https://missnzhelele.co.za/" className="hover:text-yellow-500">Home</a>
          <a href="https://tickets.missnzhelele.co.za/" className="hover:text-yellow-500">View Tickets</a>
          {/* <a href="/magazine" className="hover:text-yellow-500">Magazine</a> */}
          <a href="https://tickets.missnzhelele.co.za/" className="hover:text-yellow-500">Login</a>
          {/* Add Download Link */}
          <a 
            href="/assets/magazine.pdf" 
            download="magazine.pdf" 
            className="hover:text-yellow-500 bg-yellow-500 text-black px-2 py-1 rounded transition duration-300"
          >
            Download Magazine
          </a>
        </nav>

        <button onClick={toggleMenu} className="md:hidden text-yellow-500 focus:outline-none">
          {isMenuOpen ? (
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
        </button>
      </div>
      {isMenuOpen && (
        <div className="md:hidden bg-black text-white px-4 py-2 space-y-2">
          <a href="https://missnzhelele.co.za/" className="block hover:text-yellow-500">
            Home
          </a>
          <a href="https://tickets.missnzhelele.co.za/" className="block hover:text-yellow-500">
            View Tickets
          </a>
          {/* <a href="/magazine" className="block hover:text-yellow-500">
            Magazine
          </a> */}
          <a href="https://tickets.missnzhelele.co.za/" className="block hover:text-yellow-500">
            Login
          </a>
          {/* Add Download Link in Mobile Menu */}
          <a 
            href="/assets/magazine.pdf" 
            download="magazine.pdf" 
            className="block hover:text-yellow-500 bg-yellow-500 text-black px-2 py-1 rounded transition duration-300"
          >
            Download Magazine
          </a>
        </div>
      )}
    </header>
  );
};

export default Header;
