import React from 'react';
import Header from '../components/common/Header';
import Contestants from '../components/home/Contestants';
import Footer from '../components/layout/Footer';
import Judges from '../components/home/Judges';
import Events from '../components/home/Events';
import Highlights from '../components/home/Highlights';
import Faq from '../components/home/Faq';

const Home = () => {
  const redirectToTickets = () => {
    window.location.href = 'https://tickets.missnzhelele.co.za/';
  };

  return (
    <div className="min-h-screen bg-black">
      <Header />
      {/* Banner Section */}
      <div className="relative h-screen">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url('https://img.freepik.com/free-photo/still-life-ruler-crown_23-2150248872.jpg?t=st=1731833530~exp=1731837130~hmac=b7c8481f54ad960b4af1c6e8030e2905f68bed8dd33884265c567773500c713f&w=826')`,
          }}
        >
          <div className="absolute inset-0 bg-black/50"></div>
        </div>

        {/* Banner Content */}
        <div className="relative z-10 flex flex-col items-center justify-center h-full px-4 text-white">
          <p className="mb-4 text-lg font-light">FORCE FOR HOPE</p>
          <h1 className="mb-8 text-4xl md:text-6xl font-bold text-center">
            <span className="block">WHO WILL BE CROWNED</span>
            <span className="block">Miss Nzhelele <span className="text-yellow-500">2024</span></span>
          </h1>

          {/* Redirect Button */}
          <button 
            onClick={redirectToTickets} 
            className="bg-yellow-500 hover:bg-yellow-600 text-black px-8 py-3 rounded-lg transition-colors mt-4"
          >
            Buy Ticket
          </button>
        </div>
      </div>

      <Contestants />
      <Judges />
      <Events />
      <Highlights />
      <Faq />
      <Footer />
    </div>
  );
};

export default Home;
