import React from 'react';
import Header from '../components/common/Header';

const ambani = '/assets/images/ambani.png';
const nosi = '/assets/images/nosi.png';
const bannerBg = '/assets/images/right-side.jpeg';

// Background image for the right side
const rightSideBg = '/assets/images/vip-on.jpeg';


// QR code image (replace with actual path)
const qrCodeImage = '/assets/images/barcode.jpg'; // Change this to the path of your QR code image

const Ticket = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <Header />
      {/* Banner Section */}
      <section
        className="relative bg-cover bg-center text-white h-[60vh] flex items-center justify-center"
        style={{
          backgroundImage: `url(${bannerBg})`,
          backgroundBlendMode: 'multiply',
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
        }}
      >
        <div className="text-center max-w-3xl px-4">
          <h1 className="text-5xl md:text-7xl font-bold font-serif">
            <span className="text-yellow-400">Miss</span> Nzhelele 2024
          </h1>
          <h2 className="text-2xl md:text-4xl mt-6 font-serif italic">Urban African Royalty Edition</h2>
        </div>
      </section>

      {/* Ticket Section */}
      <div className="flex justify-center sm:p-12 md:p-16 lg:p-32 mt-8 t-32">
        <div className="flex w-full max-w-[1200px] shadow-2xl rounded-lg overflow-hidden">
          
          {/* Right Section */}
          <div 
            className="flex-1 bg-black text-white p-5 sm:p-10 relative h-24 sm:h-80 md:h-96"
            style={{
              backgroundImage: `url(${rightSideBg})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
          </div>
        </div>
      </div>

      {/* QR Code and User Details Section */}
      <div className="flex flex-col md:flex-row justify-center mt-8 px-4 sm:px-12 lg:px-32">
        <div className="flex flex-col md:flex-row w-full max-w-[1200px] shadow-2xl rounded-lg overflow-hidden bg-white">
          {/* QR Code Section */}
          <div className="flex-1 flex justify-center items-center bg-gray-200 p-6">
            <img src={qrCodeImage} alt="QR Code" className="max-w-full max-h-40" />
          </div>
          {/* User Details Section */}
          <div className="flex-1 p-6">
            <h3 className="text-xl font-bold mb-4">User Details</h3>
            <p>Name: John Doe</p>
            <p>Email: john.doe@example.com</p>
            <p>Event: Miss Nzhelele 2024</p>
            <p>Ticket Type: VIP</p>
          </div>
        </div>
      </div>

      {/* Footer Section */}
      <footer className="text-center mt-8 py-4 bg-gray-800 text-white">
        <p>&copy; 2024 Miss Nzhelele. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Ticket;
