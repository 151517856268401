import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// Specify the worker path for PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const Magazine = () => {
  // State to manage PDF file and number of pages
  const [file, setFile] = useState('/assets/magazine.pdf');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Function to handle PDF load success
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setIsLoading(false);
    setError(null);
  };

  // Function to handle PDF load error
  const onDocumentLoadError = (error) => {
    console.error('PDF load error:', error);
    setError('Failed to load PDF. Please try again later or contact support.');
    setIsLoading(false);
  };

  // Function to download PDF
  const downloadPDF = () => {
    try {
      const link = document.createElement('a');
      link.href = file;
      link.download = 'magazine.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (downloadError) {
      console.error('Download error:', downloadError);
      alert('Failed to download PDF. Please try again later or contact support.');
    }
  };

  // Pagination functions
  const goToPrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const goToNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col items-center">
        {/* Download Button */}
        <button 
          onClick={downloadPDF}
          className="mb-4 px-4 py-2 bg-yellow-500 text-black rounded hover:bg-yellow-600 transition duration-300"
        >
          Download Magazine
        </button>

        {/* Loading State */}
        {isLoading && (
          <div className="text-center text-gray-600">
            Loading PDF...
          </div>
        )}

        {/* Error Handling */}
        {error && (
          <div className="text-center text-red-600 mb-4">
            {error}
          </div>
        )}

        {/* Fallback UI for PDF load failure */}
        {error && !isLoading && (
          <div className="text-center text-red-600 mb-4">
            <p>Sorry, we couldn't load the PDF. Please try again later or contact support.</p>
          </div>
        )}

        {/* PDF Document Viewer */}
        {!error && !isLoading && (
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={onDocumentLoadError}
            className="shadow-lg"
          >
            {/* Render current page with navigation */}
            <div className="flex flex-col items-center">
              <Page
                pageNumber={pageNumber}
                width={Math.min(window.innerWidth * 0.9, 800)} // Responsive width
                className="mb-4"
              />

              {/* Page Navigation */}
              <div className="flex items-center space-x-4 mt-4">
                <button 
                  onClick={goToPrevPage}
                  disabled={pageNumber <= 1}
                  className="px-4 py-2 bg-gray-200 rounded disabled:opacity-50"
                >
                  Previous
                </button>
                <p className="text-gray-600">
                  Page {pageNumber} of {numPages}
                </p>
                <button 
                  onClick={goToNextPage}
                  disabled={pageNumber >= numPages}
                  className="px-4 py-2 bg-gray-200 rounded disabled:opacity-50"
                >
                  Next
                </button>
              </div>
            </div>
          </Document>
        )}
      </div>
    </div>
  );
};

export default Magazine;