import axios from 'axios';

// Base URL for the authentication API
const AUTH_API_URL = 'http://localhost:5000/api/auth';
// Base URL for the ticket purchasing API
const TICKET_API_URL = 'http://localhost:5000/api/tickets';

// Function to register a new user
export const registerUser = async (data) => {
  try {
    return await axios.post(`${AUTH_API_URL}/register`, data);
  } catch (error) {
    throw error.response?.data || new Error('Registration failed');
  }
};

// Function to log in a user
export const loginUser = async (data) => {
  try {
    return await axios.post(`${AUTH_API_URL}/login`, data);
  } catch (error) {
    throw error.response?.data || new Error('Login failed');
  }
};

// Function to handle forgotten passwords
export const forgotPassword = async (email) => {
  try {
    return await axios.post(`${AUTH_API_URL}/forgot-password`, { email });
  } catch (error) {
    throw error.response?.data || new Error('Forgot password process failed');
  }
};

// Function for purchasing tickets
export const buyTicket = async (ticketDetails) => {
  // Retrieve the token from local storage
  const token = JSON.parse(localStorage.getItem('user'))?.token; // Ensure you are retrieving the correct token
  if (!token) {
    throw new Error('Token based, You must be logged in to purchase tickets');
  }

  // Setup the configuration to include the token in headers
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    return await axios.post(`${TICKET_API_URL}/purchase`, ticketDetails, config);
  } catch (error) {
    throw error.response?.data || new Error('Ticket purchase failed');
  }
};
