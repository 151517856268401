import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';

const Faq = () => {
  const [faqOpen, setFaqOpen] = useState(null);

  const toggleFaq = (index) => {
    setFaqOpen(faqOpen === index ? null : index);
  };

  return (
    <div className="px-8 py-12 bg-white">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-black mb-6">FAQ</h2>
        <div className="space-y-4">
          {[
            {
              question: 'When and where will Miss Nzhelele 2024 take place?',
              answer: 'Miss Nzhelele 2024 will be held on 22nd December 2024 at 10:00 AM at the Makhado Community Hall in Biaba, Nzhelele. Join us for a spectacular celebration of beauty, culture, and talent as 20 contestants compete for the crown.',
            },
            {
              question: 'How can I attend or support Miss Nzhelele 2024?',
              answer: 'Tickets for the Miss Nzhelele 2024 Finale are available online at www.missnzhelele.co.za. You can also support the pageant by following our social media pages for updates or partnering with us as a sponsor. For sponsorship opportunities, please contact us for more information.',
            },
          ].map((faq, index) => (
            <div key={index}>
              <button
                onClick={() => toggleFaq(index)}
                className="w-full text-left flex justify-between items-center p-4 bg-gray-100 rounded-lg shadow-sm hover:bg-gray-100 focus:outline-none transition"
              >
                <span className="font-medium text-lg">{faq.question}</span>
                <ChevronDown className={`transition-transform ${faqOpen === index ? 'rotate-180' : ''}`} />
              </button>
              {faqOpen === index && (
                <p className="mt-2 text-gray-700 px-4">{faq.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
