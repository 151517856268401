// services/auth.service.js
export const authService = {
    getCurrentUser: () => {
      // Simulate a network request with a promise
      return new Promise((resolve) => {
        // Mock user data
        setTimeout(() => resolve({ id: 1, name: 'Mock User' }), 1000);
      });
    },
    login: (email, password) => {
      // Mock login response
      return new Promise((resolve) => {
        setTimeout(() => resolve({ token: 'mockToken', user: { id: 1, name: 'Mock User' } }), 1000);
      });
    },
    register: (userData) => {
      // Mock register response
      return new Promise((resolve) => {
        setTimeout(() => resolve({ token: 'mockToken', user: { id: 1, name: 'Mock User' } }), 1000);
      });
    }
  };
  