import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../services/api'; // Assuming your API functions are in services/api.js

const Login = ({ onSwitchToRegister, onForgotPassword, onLoginSuccess }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await loginUser(formData); // Call the API
      alert(response.data.message); // Display success message
      
      // Check if login is successful
      if (response.data.token) {
        // Save user info to localStorage
        localStorage.setItem('user', JSON.stringify(response.data.user));
        // Call onLoginSuccess with user data
        onLoginSuccess(response.data.user);
        // Redirect to the ticket page
        navigate('/ticket'); 
      }
    } catch (error) {
      alert(error.response?.data?.message || 'Login failed'); // Handle error
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4 text-center text-black">Login</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex flex-col">
          <label htmlFor="email" className="text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            className="mt-1 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500 text-black"
            required
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="password" className="text-sm font-medium text-gray-700">Password</label>
          <input
            type="password"
            id="password"
            value={formData.password}
            onChange={handleChange}
            className="mt-1 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500 text-black"
            required
          />
        </div>
        <button type="submit" className="w-full bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 rounded-md">
          Login
        </button>
      </form>
      <div className="mt-4 text-sm text-center">
        <button onClick={onForgotPassword} className="text-yellow-500 hover:underline">
          Forgot Password?
        </button>
      </div>
      <div className="mt-2 text-sm text-center">
        Don't have an account?{' '}
        <button onClick={onSwitchToRegister} className="text-yellow-500 hover:underline">
          Register here
        </button>
      </div>
    </div>
  );
};

export default Login;
