import React, {useState} from "react";
import {  ChevronLeft, ChevronRight, ChevronDown } from 'lucide-react';

const Highlights = () => {

    const [currentSlide, setCurrentSlide] = useState(0);

    const handlePrevSlide = () => {
        setCurrentSlide((prev) => (prev === 0 ? highlights.length - 1 : prev - 1));
      };
    
      const handleNextSlide = () => {
        setCurrentSlide((prev) => (prev === highlights.length - 1 ? 0 : prev + 1));
      };

      const highlights = [
        { type: 'image', src: '/assets/images/lesomo.jpg2light2.mp4', title: 'Highlight 2' },
        { type: 'image', src: '/assets/images/angel.jpg', title: 'Highlight 3' },
      ];

    return (
        <div className="px-8 py-12 bg-gray-100">
        <div className="max-w-7xl mx-auto">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-3xl font-bold text-black">Keep Up with <span className="text-yellow-500">Miss Nzhelele</span></h2>
            <button className="bg-yellow-500 hover:bg-yellow-600 text-black px-6 py-2 rounded-lg transition-colors">
              View all →
            </button>
          </div>
          <div className="relative flex justify-center items-center">
            <button onClick={handlePrevSlide} className="absolute left-0 bg-white p-2 rounded-full shadow-lg hover:bg-gray-200">
              <ChevronLeft />
            </button>
            <div className="flex space-x-1 overflow-hidden">
              {highlights.map((highlight, index) => {
                const isActive = index === currentSlide;
                return (
                  <div
                    key={index}
                    className={`transition-transform duration-500 ${isActive ? 'scale-100' : 'scale-75 opacity-50'}`}
                    style={{
                      transform: `translateX(${(index - currentSlide) * 100}%)`,
                      width: isActive ? '80vw' : '60vw',
                      maxWidth: '800px',
                    }}
                  >
                    {highlight.type === 'image' ? (
                      <img
                        src={highlight.src}
                        alt={highlight.title}
                        className="w-full h-64 object-cover rounded-lg shadow-md"
                      />
                    ) : (
                      <video
                        src={highlight.src}
                        controls
                        className="w-full h-64 object-cover rounded-lg shadow-md"
                      />
                    )}
                  </div>
                );
              })}
            </div>
            <button onClick={handleNextSlide} className="absolute right-0 bg-white p-2 rounded-full shadow-lg hover:bg-gray-200">
              <ChevronRight />
            </button>
          </div>
        </div>
      </div>
    );
};

export default Highlights;