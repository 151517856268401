import React from "react";
import data from '../../data.json'; 

const Events = () => {
    const { tickets } = data;
        
    return (
        <div className="px-8 py-12 bg-white">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl font-bold text-black mb-6">
            Our <span className="text-yellow-500">Events</span>
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            {tickets.map((ticket, index) => (
              <div key={index} className={`${ticket.bgColor} rounded-lg overflow-hidden shadow-md`}>
                <img src={ticket.imageUrl} alt={ticket.type} className="w-full h-48 object-cover" />
                <div className="p-4 text-center">
                  <h3 className="font-medium text-lg text-black">{ticket.type} Ticket | 22-12-2024</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
};

export default Events;