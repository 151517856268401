import React from 'react';
import data from '../../data.json'; 

const Judges = () => {

    const { judges } = data;

    return (
    <div className="px-8 py-12 bg-gray-100">
    <div className="max-w-7xl mx-auto">
      <h2 className="text-3xl font-bold text-black mb-6">
        Meet Our <span className="text-yellow-500">Judges</span>
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {judges.map((judge, index) => (
          <div key={index} className="bg-white rounded-lg overflow-hidden shadow-md">
            <img src={judge.imageUrl} alt={judge.name} className="w-full h-54 object-cover" />
            <div className="p-4">
              <h3 className="font-medium text-lg text-black">{judge.name}</h3>
              <p className="text-sm text-gray-600">{judge.organization}</p>
              <p className="text-sm text-gray-800 mt-2">{judge.achievement}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>

    );
};

export default Judges;