import React, { useState } from 'react';
import { MapPin, Heart, } from 'lucide-react';
import data from '../../data.json'; 



const Contestants = () => {
    const { events } = data;
    const [likes, setLikes] = useState({});

    const handleLike = (eventId, e) => {
        e.stopPropagation();
        setLikes(prev => ({
          ...prev,
          [eventId]: (prev[eventId] || 0) + 1,
        }));
      };

      const handleCardClick = (event) => {
        console.log('Navigate to event:', event.title);
      };

      const quickFilters = ['All', 'Top 20', 'Top 10', '2023', 'Top 40', 'Top 5'];
return (
  <div className="px-8 py-8 md:py-8 bg-white mt-6 md:mt-12">
 <div className="max-w-7xl mx-auto">
   <div className="flex justify-between items-center mb-6">
     <h2 className="text-3xl font-bold text-black">
      Meet our <span className="text-yellow-500">Flowers</span>
     </h2>
     <button className="bg-yellow-500 hover:bg-yellow-600 text-black px-6 py-2 rounded-lg transition-colors">
       View all →
     </button>
   </div>

   <div className="flex gap-2 mb-8 overflow-x-auto pb-2">
     {quickFilters.map((filter, index) => (
       <button
         key={index}
         className={`px-4 py-2 rounded-full whitespace-nowrap ${
           index === 0
             ? 'bg-yellow-500 text-black'
             : 'border border-yellow-500 text-black hover:bg-yellow-500/10'
         }`}
       >
         {filter}
       </button>
     ))}
   </div>

   <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
     {events.map((event, index) => (
       <div 
         key={index} 
         className="relative bg-gray-900 rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow cursor-pointer"
         onClick={() => handleCardClick(event)}
       >
         <img src={event.imageUrl} alt={event.title} className="w-full h-50 object-cover" />
         
         <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent"></div>
         
         <div className="relative p-4">
           <h3 className="font-medium text-lg mb-2 text-white">{event.title}</h3>
           <div className="flex items-center text-sm text-gray-400 mb-1">
             <MapPin className="w-4 h-4 mr-1" />
             {event.location}
           </div>
           <div className="text-sm text-gray-400">
             {event.date} 
           </div>
           <div className="mt-3">
             <span className="font-bold text-yellow-500">{event.price}</span>
           </div>
           
           <div className="border-t border-gray-700 my-3"></div>
           
           <div className="flex justify-between items-center">
             <div className="flex items-center text-sm text-gray-400">
               <img src="/api/placeholder/24/24" alt="organizer" className="w-6 h-6 rounded-full mr-2" />
               {event.organizer}
             </div>
             <div className="flex items-center gap-4">
               <button 
                 className="flex items-center text-gray-400 hover:text-yellow-500"
                 onClick={(e) => handleLike(event.id, e)}
               >
                 <Heart className="w-5 h-5 mr-1" />
                 <span>{likes[event.id] || 0}</span>
               </button>
               <button className="bg-yellow-500 hover:bg-yellow-600 text-black px-4 py-1 rounded">
                 Vote
               </button>
             </div>
           </div>
         </div>
       </div>
     ))}
   </div>
 </div>
</div>
);
 

};

export default Contestants;